import React, { useEffect, useState } from "react";
import CommonSlider from "../../common/commonSliderLayout/commonSlider";
// import SectionTitle from "../../common/sectionTitleText/SectionTitle";
import HeighlightsCard from "../../common/sliderCard/HighlightsCard";
import Spinner from "../../common/spinner/Spinner";
import "../videoSliderSection/videos.scss";
// import data from "./videoData";

const data = [
  {
    id: 1,
    title: "Hardik Pandya Batting | 158* Runs in 55 Balls | Second Hundred in DY Patil T20 Cup 2020",
    thumbnail: "https://www.youtube.com/embed/SLUZL9jwEzk?si=evu1s58FmXZypNs8",
    date: "2023-01-01",
    url: "https://youtu.be/SLUZL9jwEzk?si=3LCjjedtpJDOq4aG",
    city: "Chennai",
  },
  {
    id: 2,
    title: "Arjun Tendulkar Batting | T20 LEAGUE 2019",
    thumbnail: "https://www.youtube.com/embed/PvV6oi-av5Q?si=bEppQx01ydeOHcw6",
    date: "2023-01-02",
    url: "https://youtu.be/PvV6oi-av5Q?si=7x7sh1az2Fd6TI7a",
    city: "Mumbai",
  },
  {
    id: 3,
    title: "Hardik Pandya’s Batting | 105 Runs in 39 Balls | in DY Patil T20 Cup 2020",
    thumbnail: "https://www.youtube.com/embed/dsSqSeww0fQ?si=AFyhHCcZ0RkCfLi_",
    date: "2023-01-03",
    url: "https://youtu.be/dsSqSeww0fQ?si=GixrbbJQNR0ITJMl",
    city: "Kolkata",
  },
  {
    id: 4,
    title: "ARJUN TENDULKAR BOWLING | SON OF SACHIN TENDULKAR |",
    thumbnail: "https://www.youtube.com/embed/cKAfruhpayg?si=iW3WpRFDj1MWH9nP",
    date: "2023-01-03",
    url: "https://youtu.be/cKAfruhpayg?si=M1wqyzphzStxYJia",
    city: "Kolkata",
  },
  {
    id: 5,
    title: "ARJUN TENDULKAR BOWLING | SON OF SACHIN TENDULKAR |",
    thumbnail: "https://www.youtube.com/embed/cKAfruhpayg?si=LgmUG5aSLO-Y7f8L",
    date: "2023-01-03",
    url: "https://youtu.be/cKAfruhpayg?si=SOVyXuiyFb41f5_o",
    city: "Kolkata",
  },
  {
    id: 6,
    title: "Wheelchair Cricketer | SOMJEET SINGH BATTING | India VS Nepal",
    thumbnail: "https://www.youtube.com/embed/_WINGGI90KI?si=rGc78WoRL1iwYT0q",
    date: "2023-01-03",
    url: "https://youtu.be/_WINGGI90KI?si=zhfkoIzxsVZA_kku",
    city: "Kolkata",
  },
  {
    id: 7,
    title: "USMAN PATEL 50 IN 16 BALLS | 10PL 2018 , DUBAI",
    // thumbnail: "https://cdn.iconscout.com/icon/free/png-256/free-youtube-logo-icon-download-in-svg-png-gif-file-formats--social-media-70-flat-icons-color-pack-logos-432560.png",
    thumbnail: "https://www.youtube.com/embed/Q9A6gdz1aF8?si=jBNlbrslq7Ldr6ue",
    date: "2023-01-03",
    url: "https://youtu.be/Q9A6gdz1aF8?si=JdulIVRpEAMfYo8O",
    city: "Kolkata",
  },
];

interface VideoData {
  id: number;
  title: string;
  thumbnail: string;
  date: string;
  url: string;
  city: string;
}

const VideoSlider: React.FC = () => {
  const [selectedCity, setSelectedCity] = useState("Highlights");
  // const [videos, setVideos] = useState<VideoData[]>(data);
  // const [filteredVideos, setFilteredVideos] = useState<VideoData[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleCityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCity(event.target.value);
  };

  const filteredVideos =
    selectedCity === "Highlights"
      ? data
      : data.filter((video) => video.city === selectedCity);

  return (
    <section id="highlightsSection">
      <div className="container">
        <div className="row">
          <div className="heading-dropdown">
            <div className="heading">
              <h6>Videos</h6>
            </div>

            {/* <div className="dropdown-videos">
              Choose Videos
              <select
                value={selectedCity}
                name="city-names"
                id="city"
                onChange={handleCityChange}
              >
                <option value="Highlights">Highlights</option>
                <option value="Chennai">Chennai</option>
                <option value="Mumbai">Mumbai</option>
                <option value="Kolkata">Kolkata</option>
                <option value="Srinagar">Srinagar</option>
                <option value="Bangalore">Bangalore</option>
                <option value="Hyderabad">Hyderabad</option>
              </select>
            </div> */}
          </div>

          <div className="col-l2-10 col-md-12 col-12 mx-auto px-0">
            {/* <div className="topSecWrap">
              <SectionTitle/>
            </div> */}
            {loading ? (
              <Spinner />
            ) : (
              <CommonSlider>
                {filteredVideos.map((item: VideoData) => (
                  <div className="col-md-3" key={item.id}>
                    <HeighlightsCard
                    id={item.id}
                      mainTitle={item.title}
                      backgroundImg={item.thumbnail}
                      date={item.date}
                      matchLink={item.url}
                      datafancybox="video-gallery"
                      title={item.title}
                      thumbnail={item.thumbnail}
                      url={item.url}
                      city={item.city}
                    />
                  </div>
                ))}
              </CommonSlider>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoSlider;
