import React from "react";
import { Link } from "react-router-dom";
import "../sliderCard/highlightsCard.scss";

interface CardProps {
  id: number;
  title: string;
  mainTitle?: string;
  backgroundImg?: string;
  thumbnail: string;
  date: string;
  matchLink?: string;
  shareLink?: string;
  timeText?: string;
  target?: string;
  datafancybox?: string;
  url: string;
  city?: string
}

const HighlightsCard: React.FC<CardProps> = ({
  mainTitle,
  backgroundImg,
  matchLink,
  datafancybox,
  url
}) => {
  return (
    <div className="position-relative">
      <div className="cardInner">
        <Link
          to={matchLink || "#"}
          className="cardAllWrap"
          data-fancybox={datafancybox}
          data-src={url}
        >
          <div className="imgWrap">
            {/* <img src={backgroundImg} alt={mainTitle} className="img-fluid" loading="lazy" /> */}
            <iframe width="560" height="315" src={backgroundImg} allowFullScreen loading="lazy"></iframe>
            {/* <div className="playBtn">
                <img src="images\playIcon.png" alt="play-icon"/>
            </div> */}
          </div>
          <div className="midBox">
            <h5>{mainTitle}</h5>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default HighlightsCard;
