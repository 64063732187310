import "./MenuItems.scss";
import { Link } from "react-router-dom";
import "../navigation/Navigation.scss";

interface MenuItemsProps {
  menuOpen: boolean;
  isLoggedIn: boolean;
  handleLogout: () => void;
}

const MenuItems: React.FC<MenuItemsProps> = ({
  menuOpen,
  isLoggedIn,
  handleLogout,
}) => {
  return (
    <div className="col-md-6 col menu">
      {/* <div className="collapse navbar-collapse" id="navbarNav"> */}
      <div
        className={`navbar-collapse ${menuOpen ? "show" : "collapse"}`}
        id="navbarNav"
      >
        <ul className="navbar-nav">
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              to="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Live Score
            </Link>
            <ul className="dropdown-menu">
              <li>
                <Link className="dropdown-item" to="/allMatches">
                  All Matches
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/domesticTournamnet">
                  Domestic Tournament
                </Link>
              </li>
              {/* <li>
                <Link className="dropdown-item" to="/matchStat">
                  Match Stat
                </Link>
              </li> */}
            </ul>
          </li>
          {/* <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              to="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Tournament
            </Link>
            <ul className="dropdown-menu">
            <li>
                <Link className="dropdown-item" to="/tournamentDashboard">
                  Tournament Dashboard
                </Link>
              </li>
            </ul>
          </li> */}
          {/* <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              to="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Network
            </Link>
            <ul className="dropdown-menu">
              <li>
                <Link className="dropdown-item" to="#">
                  Action
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="#">
                  Another action
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="#">
                  Something else here
                </Link>
              </li>
            </ul>
          </li> */}
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              to="/"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Media
            </Link>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="/#latestBlog-homePage">
                  Latest Blog
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/#topStories-homePage">
                  Top Stories
                </a>
              </li>
              {/* <li>
                <Link className="dropdown-item" to="#">
                  Something else here
                </Link>
              </li> */}
            </ul>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/contact-us">
              Contact Us
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/about-us">
              About Us
            </Link>
          </li>
        </ul>
        {isLoggedIn ? (
          // <Link to="" onClick={handleLogout} className="btn">
          //   <i className="fas fa-power-off"></i> Logout
          // </Link>
          ""
        ) : (
          <div className="mobBox">
            {/* <button className="searchWrap">
            <img
              src="images\searchIcon.svg"
              alt="search"
              width={19}
              height={19}
            />
          </button> */}
            <Link to="/registrationPage" className="btn">
            <span>
            <i className="fa-regular fa-user"></i>
            </span>{" "}
              Register
            </Link>
            <Link to="/loginPage" className="btn">
              <span>
                <i className="fa fa-sign-in"></i>
              </span>{" "}
              Login
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuItems;
